import React, { useEffect } from 'react';
import styled from 'styled-components';
import { InferGetServerSidePropsType } from 'next';
import { Text, Layout, Translation } from 'src/components';
import { trackEvent } from 'src/hooks';
import { getServerSidePropsForPage } from '../ssr/public';

type Expired = InferGetServerSidePropsType<typeof getServerSidePropsForPage>;

export const Expired = ({ clientConfig, translationStrings }: Expired): JSX.Element => {
  useEffect(() => {
    trackEvent({
      name: 'ErrorScreen',
      data: { name: 'Expired' },
    });
  }, []);

  return (
    <Layout clientConfig={clientConfig} translationStrings={translationStrings}>
      <Container>
        <Heading>
          <Text as="h1" weight="bold" size="xl">
            <Translation id="expiredPage.title" />
          </Text>
        </Heading>
        <Text as="p" size="lg">
          <Translation id="expiredPage.description" clientDisplayName={clientConfig.displayName} />
        </Text>
      </Container>
    </Layout>
  );
};

export const getServerSideProps = getServerSidePropsForPage;

const Heading = styled.div`
  padding-bottom: var(--s-2);
`;

const Container = styled.div`
  padding: var(--s-4) var(--s-6) var(--s-6);
`;

export default Expired;
